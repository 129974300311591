import React, { useEffect, useState } from "react";
import InputFields from "../../../InputField/TextField/InputField";
import Button from "../../../InputField/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getStateAction,
  getCityAction,
} from "../../../../Redux/Actions/getStateCityAction";
import SearchSelect from "../../../InputField/Searchable-Dropdown/SearchSelect";
import CheckBox from "../../../InputField/CheckBox/CheckBox";
import './EditAddressDetails.css'
import tooltip from "../../../../Assets/Images/green.svg"
import Tooltip from "../../../Layouts/Tooltip/Tooltip";

const EditAddressDetails = (props) => {
  const {
    HanldeFormData,
    FormData,
    setComponentToRender,
    handleBackBtn,
    CallForEdit,
    setFormData,
    errors,
  } = props;

  const dispatch = useDispatch();

  const [ShowNomineeRelation, setShowNomineeRelation] = useState(false);
  const [ShowStateList, setShowStateList] = useState(false);
  const [ShowCityList, setShowCityList] = useState(false);

  useEffect(() => {
    dispatch(getStateAction());
  }, []);

  const getStateList = useSelector((state) => {
    return state?.getStateList?.data?.data?.rtocity;
  });

  const getCityList = useSelector((state) => {
    return state?.getCityList?.data?.data?.rtocity;
  });

  const cityList = getCityList?.map((data) => ({
    label: data?.city_name,
    value: data?.city_name,
  }));

  const stateList = getStateList?.map((data) => ({
    label: data.state_name,
    value: data.state_name,
  }));

  const handleStateValue = (e) => {
    dispatch(getCityAction(e.value));
    setFormData((prevItems) => ({
      ...prevItems,
      state: e?.value,
    }));
  };

  const handleCityValue = (e) => {
    setFormData((prevItems) => ({
      ...prevItems,
      city: e?.value,
    }));
  };

  const handlePopUps = () => {
    if (ShowNomineeRelation) {
      setShowNomineeRelation(!ShowNomineeRelation);
    }
    if (ShowStateList) {
      setShowStateList(!ShowStateList);
    }
    if (ShowCityList) {
      setShowCityList(!ShowCityList);
    }
  };

  return (
    <section className="editVehicleDetails-container" onClick={handlePopUps}>
      <div className="editVehicleDetails-parent maxWidth">
        <div className="form-container-editVehicleDetails">
          <div className="head-form-container">
            <p>Address Details</p>
            <p className="imp-msg">All fields are mandatory</p>
          </div>

          <div className="input-list-editVehicleDetails">

            <div className="input-container-form">
              <InputFields
                type="text"
                placeholder="Address Line 1"
                name="addressLineOne"
                className=""
                onChangeEvent={HanldeFormData}
                value={FormData.addressLineOne}
                errState={errors?.addressLineOne ? true : false}
                errMsg={errors?.addressLineOne}
                errClassName="input-err"
              />
              <InputFields
                type="text"
                placeholder="Address Line 2"
                name="addressLineTwo"
                className=""
                onChangeEvent={HanldeFormData}
                value={FormData.addressLineTwo}
                errState={errors?.addressLineTwo ? true : false}
                errMsg={errors?.addressLineTwo}
                errClassName="input-err"
              />
            </div>

            <div className="input-container-form">
              <SearchSelect
                optionList={stateList}
                handleSelect={handleStateValue}
                value={FormData?.state}
                placeholder="State"
                label="State"
                name="state"
                errState={errors?.state ? true : false}
                errMsg={errors?.state}
                errClassName="input-err"
              />
              <SearchSelect
                optionList={cityList}
                handleSelect={handleCityValue}
                value={FormData?.city}
                placeholder="City"
                label="City"
                name="city"
                errState={errors?.city ? true : false}
                errMsg={errors?.city}
                errClassName="input-err"
              />
            </div>

            <div className="input-container-form">
              <InputFields
                type="number"
                placeholder="Pin code"
                name="pincode"
                className=""
                onChangeEvent={HanldeFormData}
                value={FormData.pincode}
                errState={errors?.pincode ? true : false}
                errMsg={errors?.pincode}
                errClassName="input-err"
              />
              {
                FormData.isCompleteCareChecked === true && !FormData.defaultCCPlanAmount ? (
                  <InputFields
                    type="number"
                    placeholder="CC"
                    name="cc"
                    className=""
                    onChangeEvent={HanldeFormData}
                    value={FormData.cc}
                    errState={errors?.cc ? true : false}
                    errMsg={errors?.cc}
                    errClassName="input-err"
                  />
                ) : (
                  <div></div>
                )
              }
            </div>

            <div className="input-container-form">
              <div className="checkBox-container" >
                <CheckBox
                  label="Do you wish to add complete care ?"
                  name="isCompleteCareChecked"
                  id="isCompleteCareChecked"
                  value=""
                  changeEvent={HanldeFormData}
                  checked={FormData.isCompleteCareChecked === true ? true : false}
                />
                <Tooltip content={`1. compulsory excess protection
                  2. salvage protection`}>
                  <img src={tooltip} style={{ cursor: "pointer", paddingTop: "4px" }} />
                </Tooltip>
              </div>


            </div>

            <div className="btngrp-container">
              <Button
                text="Back"
                className="secondry_btn btn-vehicledetails "
                event={(e) =>
                  handleBackBtn(
                    CallForEdit ? "dropToProductPage" : "editAddressDetails"
                  )
                }
              />
              <Button
                text="Pay Now"
                className="button btn-vehicledetails "
                event={(e) => setComponentToRender("editAddressDetails")}
              />
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default EditAddressDetails;
