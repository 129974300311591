import React, { useState, useEffect } from "react";
import "./EditVehicleDetails.css";
import grayArrow from "../../../../Assets/Images/grayArrow.svg";
import InputFields from "../../../InputField/TextField/InputField";
import DropDown from "../../../InputField/Dropdown/DropDown";
import Calender from "../../../InputField/Calender/Calender";
import Button from "../../../InputField/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { getBikeBrandAction } from "../../../../Redux/Actions/MmvListAction";
import SearchSelect from "../../../InputField/Searchable-Dropdown/SearchSelect";
import { fetchClientDetails } from "../../../../Redux/Actions/fetchClientDetails";
import Loader from "../../../Layouts/Loader/Loader";
import { getCityAction } from "../../../../Redux/Actions/getStateCityAction";
import ButtonLoader from "../../../Layouts/Loader/Button Loader/ButtonLoader";

const EditVehicleDetails = (props) => {
  const {
    HanldeFormData,
    FormData,
    setFormData,
    setComponentToRender,
    handleBackBtn,
    CallForEdit,
    errors,
    chekEngineLodar

  } = props;
  const dispatch = useDispatch();

  const [ShowMakeList, setShowMakeList] = useState(false);
  const [ShowModelList, setShowModelList] = useState(false);
  const [ShowVariantList, setShowVariantList] = useState(false);
  const [ShowCClist, setShowCClist] = useState(false);
  const [ShowFuelList, setShowFuelList] = useState(false);
  const [ShowPolicyList, setShowPolicyList] = useState(false);

  const getclientData = useSelector((state) => {
    return state?.getClientDetails;
  })

  const clientData = getclientData?.data?.data;

  const handlePopUps = () => {
    if (ShowMakeList) {
      setShowMakeList(!ShowMakeList);
    }
    if (ShowModelList) {
      setShowModelList(!ShowModelList);
    }
    if (ShowVariantList) {
      setShowVariantList(!ShowVariantList);
    }
    if (ShowCClist) {
      setShowCClist(!ShowCClist);
    }
    if (ShowFuelList) {
      setShowFuelList(!ShowFuelList);
    }
    if (ShowPolicyList) {
      setShowPolicyList(!ShowPolicyList);
    }
  };

  const handleCompanyValue = (e) => {
    setFormData((prevItems) => ({
      ...prevItems,
      companyName: e?.value,
    }));
  };

  const companyList = [
    {
      label: "ACKO",
      value: "ACKO",
    },
    {
      label: "UNITED",
      value: "UNITED",
    }, {
      label: "TATA",
      value: "TATA",
    }, {
      label: "BAJAJ",
      value: "BAJAJ",
    }, {
      label: "ZUNO",
      value: "ZUNO",
    },
  ];

  const fetchClientData = () => {
    let data = {
      "data": {
        "engineNo": FormData?.engineNumber,
      }
    }
    dispatch(fetchClientDetails(data));
  }

  useEffect(() => {
    setFormData((prevItems) => ({
      ...prevItems,
      make: clientData?.make,
      model: clientData?.model_varient,
      engineNumber: clientData?.engNo,
      chassisNumber: clientData?.chassisNo,
      policyStartDate: clientData?.policyStartDate,
      firstName: clientData?.firstName,
      lastName: clientData?.lastName,
      mobileNumber: clientData?.phone,
      emailId: clientData?.email,
      addressLineOne: clientData?.address1,
      addressLineTwo: clientData?.address2,
      state: clientData?.state,
      city: clientData?.city,
      pincode: clientData?.pincode,
    }))

    dispatch(getCityAction(FormData?.state ? FormData?.state : clientData?.state))

  }, [clientData])

  return (
    <section className="editVehicleDetails-container" onClick={handlePopUps}>

      {getclientData?.isLoading && <Loader />}

      <div className="editVehicleDetails-parent maxWidth">
        <div className="form-container-editVehicleDetails">
          <div className="head-form-container">
            <p>Vehicle details</p>
            <p className="imp-msg">All fields are mandatory</p>
          </div>

          <div className="input-list-editVehicleDetails">

            <div className="input-container-form">
              <div className="input-field-btn" >
                <InputFields
                  type="text"
                  placeholder="Engine Number"
                  name="engineNumber"
                  className="vehicleNumber"
                  onChangeEvent={HanldeFormData}
                  value={FormData.engineNumber}
                  errState={errors?.engineNumber ? true : false}
                  errMsg={errors?.engineNumber}
                  errClassName="input-err"
                />
                <Button
                  text="Fetch"
                  className={FormData.engineNumber ? "fetch-btn" : "fetch-btn disable-fetch-btn"}
                  event={fetchClientData}
                />
              </div>
              <InputFields
                type="text"
                placeholder="Chassis Number"
                name="chassisNumber"
                className="vehicleNumber"
                onChangeEvent={HanldeFormData}
                value={FormData.chassisNumber}
                errState={errors?.chassisNumber ? true : false}
                errMsg={errors?.chassisNumber}
                errClassName="input-err"
              />
            </div>

            <div className="input-container-form">
              <InputFields
                type="text"
                placeholder="Make"
                name="make"
                className="vehicleNumber"
                onChangeEvent={HanldeFormData}
                value={FormData.make}
                errState={errors?.make ? true : false}
                errMsg={errors?.make}
                errClassName="input-err"
              />
              <InputFields
                type="text"
                placeholder="Model / Variant"
                name="model"
                className="vehicleNumber"
                onChangeEvent={HanldeFormData}
                value={FormData.model}
                errState={errors?.model ? true : false}
                errMsg={errors?.model}
                errClassName="input-err"
              />
            </div>

            <div className="input-container-form">

              <InputFields
                type="number"
                placeholder="Enter Plan Price"
                name="planBuyPrice"
                className="vehicleNumber"
                onChangeEvent={HanldeFormData}
                value={FormData.planBuyPrice}
                errState={errors?.planBuyPrice ? true : false}
                errMsg={errors?.planBuyPrice}
                errClassName="input-err"
              />
               <Calender
                className="pForm_datePicker"
                placeholder="Policy Start Date"
                name="policyStartDate"
                id="policyStartDate"
                minDate={true}
                value={FormData.policyStartDate}
                changeEvent={(e) => HanldeFormData(e)}
                errMsg={errors?.policyStartDate}
              />
               
            </div>

            <div className="input-container-form">
           
            
              <SearchSelect
                optionList={companyList}
                handleSelect={handleCompanyValue}
                value={FormData?.companyName}
                placeholder="Select Company"
                label="Company"
                name="Company"
                errState={errors?.companyName ? true : false}
                errMsg={errors?.companyName}
                errClassName="input-err"
              />
              <InputFields
                type="text"
                placeholder="Enter Policy Number"
                name="policyNumber"
                className="vehicleNumber"
                onChangeEvent={HanldeFormData}
                value={FormData.policyNumber}
                errState={errors?.policyNumber ? true : false}
                errMsg={errors?.policyNumber}
                errClassName="input-err"
              />
              
            </div>

            <div className="btngrp-container">
              <Button
                text="Back"
                className="secondry_btn btn-vehicledetails "
                event={(e) =>
                  handleBackBtn(
                    CallForEdit ? "dropToProductPage" : "editCarDetails"
                  )
                }
              />
              {
                chekEngineLodar ? <ButtonLoader/> :  <Button
                text="Save"
                className="button btn-vehicledetails "
                event={(e) =>
                  setComponentToRender(
                    CallForEdit ? "editPersonalDetails" : "editCarDetails"
                  )
                }
              />
              }
             

            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

const policyTypeList = [
  { label: "Bundled", value: "Bundled" },
  { label: "Comprehensive", value: "Comprehensive" },
  { label: "Third Party", value: "Third Party" },
  { label: "Own Damage", value: "Own Damage" },
];

export default EditVehicleDetails;
